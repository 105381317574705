const url="http://lmgexhibitions.losenviskas.com/"

export const photos = [

  {
    src: url+"img/fe.jpeg",
    width: 4,
    height: 3,
     title:""
  },
  {
    src: url+"img/sne.jpeg",
    width: 4,
    height: 3,
     title:""
  },

  
  {
    src: url+"img/p1.jpeg",
    width: 4,
    height: 3,
     title:""
  },
  {
    src: url+"img/p2.jpeg",
    width: 4,
    height: 3,
     title:""
  },
  {
    src: url+"img/p3.jpeg",
    width: 1,
    height: 1,
     title:""
  },
  {
    src: url+"img/p4.jpeg",
    width: 4,
    height: 3,
     title:""
  },
  {
    src: url+"img/p5.jpeg",
    width: 1,
    height: 1,
     title:""
  },

  {
    src: url+"img/2.jpg",
    width: 4,
    height: 3,
     
  },
  {
    src: url+"img/p7.jpeg",
    width: 1,
    height: 1,
     title:""
  },
  {
    src: url+"img/ppp.jpeg",
    width: 1,
    height: 1,
     title:""
  },
  {
    src: url+"img/p16.jpg",
    width: 4,
    height: 3,
     title:""
  },
  {
    src: url+"img/7.jpg",
    width: 4,
    height: 3,
     title:""
  }
  ,
  {
    src: url+"img/5.jpg",
    width: 1,
    height: 1,
     title:""
  }
  ,  
  {
    src: url+"img/1.jpg",
    width: 4,
    height: 3,
     title:""
  },
];
