var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mTopu",staticStyle:{"max-width":"800px","margin-left":"auto","margin-right":"auto","margin-top":"20px","width":"90%"}},[(_vm.sending)?_c('md-progress-bar',{attrs:{"md-mode":"indeterminate"}}):_vm._e(),_c('mdb-card',{staticStyle:{"width":"99%","max-width":"800px","margin-bottom":"3%","padding-top":"42px","padding-bottom":"42px"}},[_c('mdb-card-body',[_c('a-form',{staticStyle:{"margin-left":"auto","margin-right":"auto","z-index":"0","justify-content":"space-around","width":"99%","max-width":"700px"},attrs:{"form":_vm.form,"layout":"vertical"},on:{"submit":_vm.handleSubmit}},[_c('md-card',{staticClass:"md-small-size-100",staticStyle:{"margin-left":"auto","margin-right":"auto"}},[_c('md-card-content',[_c('a-form-item',_vm._b({},'a-form-item',_vm.formItemLayout,false),[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v(" Name  "),_c('a-tooltip',{attrs:{"title":"What do you want us to call you?"}},[_c('a-icon',{attrs:{"type":"question-circle-o"}})],1)],1),_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'name',
       
          {   
           initialValue :_vm.name,
            rules: [{ required: true, message: 'Please input your Name!', whitespace: true,}],
          } ]),expression:"[\n          'name',\n       \n          {   \n           initialValue :name,\n            rules: [{ required: true, message: 'Please input your Name!', whitespace: true,}],\n          },\n        ]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"E-mail"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'email',
          {
           initialValue :_vm.mail,
            rules: [
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              {
                required: true,
                message: 'Please input your E-mail!',
              } ],
          } ]),expression:"[\n          'email',\n          {\n           initialValue :mail,\n            rules: [\n              {\n                type: 'email',\n                message: 'The input is not valid E-mail!',\n              },\n              {\n                required: true,\n                message: 'Please input your E-mail!',\n              },\n            ],\n          },\n        ]"}]})],1),_c('a-form-item',_vm._b({attrs:{"label":"Phone Number"}},'a-form-item',_vm.formItemLayout,false),[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'phone',
          {
           initialValue :_vm.phone,
            rules: [{ required: true, message: 'Please input your phone number!' }],
          } ]),expression:"[\n          'phone',\n          {\n           initialValue :phone,\n            rules: [{ required: true, message: 'Please input your phone number!' }],\n          },\n        ]"}],staticStyle:{"width":"100%"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['prefix', { initialValue: '254' }]),expression:"['prefix', { initialValue: '254' }]"}],staticStyle:{"width":"70px"},attrs:{"slot":"addonBefore"},slot:"addonBefore"},[_c('a-select-option',{attrs:{"value":"254"}},[_vm._v(" +254 ")])],1)],1)],1),_c('a-form-item',_vm._b({attrs:{"label":"Message"}},'a-form-item',_vm.formItemLayout,false),[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
          'msg',
          {
            rules: [{ 
                required: false, message: 'How can we help you?' }],
          } ]),expression:"[\n          'msg',\n          {\n            rules: [{ \n                required: false, message: 'How can we help you?' }],\n          },\n        ]"}],staticStyle:{"width":"100%"}})],1),_c('div',{staticClass:"text-center py-4 mt-3"},[_c('mdb-btn',{staticStyle:{"color":"#e9ecef","background-color":"#0c0f24"},attrs:{"color":"","type":"submit","disabled":_vm.sending}},[_vm._v("Send Inquery")])],1)],1)],1),_c('md-snackbar',{attrs:{"md-active":_vm.error},on:{"update:mdActive":function($event){_vm.error=$event},"update:md-active":function($event){_vm.error=$event}}},[_vm._v(_vm._s(_vm.emsg)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }